.main {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 100%;
    height: 100%;
    margin-top: 60px;
    justify-content: center;
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 50px;
    text-align: center;
    font-weight: bold;
    color: cadetblue;
}

.subtitle {
    text-align: left;
    margin-top: -20px;
    font-weight: bold;
}

.section {
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 15px;
}

.sub-title {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
    color: cadetblue;
    margin-top: 20px;
    margin-bottom: 20px;
}

#link {
    color: cadetblue;
}

.text {
    text-align: left;
}

table {
    table-layout: fixed;
    border: 3px solid cadetblue;
    width: 100%;
    border-radius: 10px;
}

tr {
    vertical-align: top;
    height: 50px;
}

th {
    font-weight: bold;
    color: cadetblue;
    padding-top: 5px;
}

td {
    width: 150px;
    text-align: left;
    padding-left: 10px;
}

td+td {
    width: auto;
    text-align: left;
}

ul {}

li {
    text-align: left;
    vertical-align: top;
    margin-bottom: 5px;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    color: black;
    margin-bottom: 5px;
}

.image-one {
    width: 100%;
    height: auto;
    border: 3px solid cadetblue;
    border-radius: 10px;
}

.image-columns {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    column-gap: 10px;
}

.image-column {
    width: 50%;
}

#pdf {
    width: 100%;
    height: 800px;
    margin-top: 10px;
    border: 3px solid cadetblue;
    border-radius: 10px;
}

#video {
    width: 80px;
}

.video {
    border: 3px solid cadetblue;
    border-radius: 10px;
}